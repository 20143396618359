import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import './Page2.css';

function Page3() {
  const [data, setData] = useState({});
  const [numberItems, setNumberItems] = useState(150);
  const [firstObject, setFirstObject] = useState({});
  const [secondObject, setSecondObject] = useState({});
  const [latestData, setLatestData] = useState(null);

  useEffect(() => {
    const fetchData = async (url, setter) => {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: 'kSuIlUmNaTiIeO5',
          },
          params: {
            number_of_items: numberItems,
          },
        });

        setter(response.data);
      } catch (error) {
        console.error(`Error fetching data: ${error}`);
      }
    };

    fetchData('https://kidr78home.tplinkdns.com/api/sahko', setData);
  }, [numberItems]);

  useEffect(() => {
    const splitData = (data) => {
      const firstObj = {};
      const secondObj = {};

      for (const [dateTime, values] of Object.entries(data)) {

	const approxCostNow = values.total_kw * 0.18
        firstObj[dateTime] = {
          car_charge_total: values.car_charge_total,
          kw_without_carcharge: values.kw_without_carcharge,
          total_kw: values.total_kw,
	  approx_cost_now: approxCostNow
        };
       
	const estimatedCost = values.estimated_per_month * 0.18;
        secondObj[dateTime] = {
          estimated_per_month: values.estimated_per_month,
	  estimated_cost: estimatedCost
        };
      }

      setFirstObject(firstObj);
      setSecondObject(secondObj);

      const keys = Object.keys(data);
      const lastKey = keys[keys.length - 1];
      setLatestData(data[lastKey] || {});
    };
    splitData(data);
  }, [data]);

  // Create chart data and options
  const createChartData = (dataObject, colorIndex) => {
    const predefinedColors = ['#FF5733', '#33FF57', '#5733FF', '#FFD700'];
    const labels = Object.keys(dataObject);

    if (labels.length === 0) {
      return null; // No data available
    }

    const datasets = Object.keys(dataObject[labels[0]]).map((key, index) => ({
      label: key,
      data: labels.map((label) => dataObject[label][key]),
      fill: false,
      borderColor: predefinedColors[index % predefinedColors.length],
      pointBackgroundColor: predefinedColors[index % predefinedColors.length],
    }));

    return {
      labels,
      datasets,
    };
  };

  const chartData = createChartData(firstObject, 0);
  const chartDataEst = createChartData(secondObject, 1);


  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Values',
        },
      },
    },
  };

  return (
    <>
      <div
        id="infodiv"
        className="floating-div"
      >
      {latestData ? (
          <>
            <div>Now KW: {latestData.total_kw !== undefined ? latestData.total_kw.toFixed(2) : 'Loading...'}</div>
            <div>Now Eur: {latestData.total_kw !== undefined ? (latestData.total_kw * 0.18).toFixed(2) : 'Loading...'}</div>
            <div>Car: {latestData.car_charge_total !== undefined ? latestData.car_charge_total.toFixed(2) : 'Loading...'}</div>
            <div>Car Eur: {latestData.car_charge_total !== undefined ? (latestData.car_charge_total * 0.18).toFixed(2) : 'Loading...'}</div>
            <div>Elec KW: {latestData.kw_without_carcharge !== undefined ? latestData.kw_without_carcharge.toFixed(2) : 'Loading...'}</div>
            <div>Elec Eur: {latestData.kw_without_carcharge !== undefined ? (latestData.kw_without_carcharge * 0.18).toFixed(2) : 'Loading...'}</div>
            <div>Est Month: {latestData.estimated_per_month !== undefined ? latestData.estimated_per_month.toFixed(2) : 'Loading...'}</div>
            <div>Est Cost Month: {latestData.estimated_per_month !== undefined ? (latestData.estimated_per_month * 0.18).toFixed(2) : 'Loading...'}</div>
          </>
        ) : (
          <div>Loading data...</div>
        )}
      </div>

      <div className="select_bar">
        <label htmlFor="numberItems">Items:</label>
        <input
          type="range"
          min="50"
          max="5000"
          step="10"
          id="numberItems"
          value={numberItems}
          onChange={(e) => setNumberItems(Number(e.target.value))}
        />
        <span>{numberItems}</span>
      </div>
      {chartData ? (
        <div className="graph-container">
          <div className="chart-title">Electricity Consumption</div>
          <Line data={chartData} options={options} />
        </div>
      ) : (
        <div>No data available for Electricity Consumption</div>
      )}
      {chartDataEst ? (
        <div className="graph-container">
          <div className="chart-title">Electricity Estimations</div>
          <Line data={chartDataEst} options={options} />
        </div>
      ) : (
        <div>No data available for Electricity Estimations</div>
      )}
    </>
  );
}

export default Page3;

