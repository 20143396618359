// src/components/CV.js
import React, { useState, useEffect } from 'react';
import './CV.css';

const CV = () => {

	const [isListHidden1, setIsListHidden1] = useState(true);
	const [isListHidden2, setIsListHidden2] = useState(true);
	const [isListHidden3, setIsListHidden3] = useState(true);
	const [isListHidden4, setIsListHidden4] = useState(true);
	const [isListHidden5, setIsListHidden5] = useState(true);
	const [isListHidden6, setIsListHidden6] = useState(true);
	const [isListHidden7, setIsListHidden7] = useState(true);
	const [isListHidden8, setIsListHidden8] = useState(true);
	const [isListHidden9, setIsListHidden9] = useState(true);
	const [isListHidden10, setIsListHidden10] = useState(true);
	const [isListHidden11, setIsListHidden11] = useState(true);

	const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position and set state accordingly
      setIsScrolled(window.scrollY > 5); // Adjust the scroll threshold as needed
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className={`cv-container ${isScrolled ? 'scrolled' : ''}`}>
	  {/*<div className="cv-container">*/}
      <header>
	  {/*<img src="CV.jpg" alt="Kirill Dremov" className="global-job-photo" />*/}
	  <img src="CV.jpg" alt="Kirill Dremov" className={`global-job-photo ${isScrolled ? 'scrolled' : ''}`} />

	<div className="cvhead">
        <h1>Kirill Dremov</h1>
        <p>Experienced SW Engineer</p>
	</div>
      </header>

      <section className="contact">
        <h2>Contact Information</h2>
        <p>Email: kirdrem@gmail.com</p>
        <p>Phone: +358503853141</p>
	<p>LinkedIn: <a href="https://www.linkedin.com/in/kirill-dremov" target="_blank" rel="noopener noreferrer">linkedin.com/in/kirill-dremov</a></p>
      </section>
      <section className="skills">
	  <h2>Skills</h2>
	  <div className="job">
	  <ul>
	  	<li>Programming: Python, Groovy, JavaScript/Typescript, PHP</li>
	  </ul>
	  </div>
      </section>
      <section className="experience">
        <h2>Work Experience</h2>
        <div className="job">
	 <h3 className={isListHidden1 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden1(!isListHidden1)}>
        	KONE - Senior DevOps Engineer
      	 </h3>
	  {!isListHidden1 && (
	  <div className="note-content">
          <p>
            KONE · Full-time
            <br />
            Mar 2022 - Present · 1 yr 11 mos
            <br />
            Espoo, Uusimaa, Finland
          </p>
          <ul>
            <li>Responsible for developing and maintaining solutions used to develop and run Kone solutions.</li>
            <li>Cloud architecture and engineering: AWS</li>
            <li>Services used daily: S3, EC2, Lambda, CloudFront, EKS, ECR, Cognito, Amplify, Athena, RDS, DynamoDb, Route53, SNS, SQS</li>
            <li>Version control, pipelines: Gitlab, Jenkins</li>
            <li>Languages: Python, Typescript/Javascript (React, Redux)</li>
          </ul>
	  </div>)}	  
        </div>
        <div className="job">
	  <h3 className={isListHidden2 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden2(!isListHidden2)}>
          Nordea - Senior Software Engineer
	  </h3>
	  {!isListHidden2 && (
          <div className="note-content">
          <p>
            Nordea · Full-time
            <br />
            Mar 2018 - Mar 2022 · 4 yrs 1 mo
            <br />
            Helsinki Area, Finland
          </p>
          <ul>
            <li>Responsible for developing and maintaining Development and Production solutions hosting Nordea's microservices.</li>
          </ul>
	  </div>)}
        </div>
	

        <div className="job">
	  <h3 className={isListHidden3 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden3(!isListHidden3)}>
          Qvantel - Release Manager</h3>
	   {!isListHidden3 && (
          <div className="note-content">
          <p>
            Qvantel
            <br />
            Jan 2017 - Mar 2018 · 1 yr 3 mos
            <br />
            Helsinki Area, Finland
          </p>
          <ul>
            <li>Cloud engineering: Amazon Cloud administration, Google Cloud Platform</li>
            <li>Virtualization: VMware</li>
          </ul>
	 </div>)}
        </div>

        <div className="job">
	  <h3 className={isListHidden11 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden11(!isListHidden11)}>
          Microsoft - Senior Specialist</h3>
	  {!isListHidden11 && (
          <div className="note-content">
          <p>
            Microsoft
            <br />
            May 2014 - Jan 2017 · 2 yrs 9 mos
            <br />
            Salo, Finland
          </p>
          <ul>
            <li>Responsible for Scheduler Build System and Infrastructure. Worked together with Build and Integration teams and IT support to keep build infrastructure operational.</li>
            <li>Tools used: Perforce, Jenkins, MSBuild, Scripting (Java, Groovy, Python, Windows Batch, Power Shell).</li>
          </ul>
	</div>)}
        </div>

        <div className="job">
	  <h3 className={isListHidden4 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden4(!isListHidden4)}>
          Nokia - Build System Manager Nokia with Windows Phone</h3>
	  {!isListHidden4 && (
          <div className="note-content">
          <p>
            Nokia
            <br />
            Jul 2011 - May 2014 · 2 yrs 11 mos
            <br />
            Tampere
          </p>
          <ul>
            <li>Build System Support, Maintenance, and Optimization. Site Support for Development Teams.</li>
          </ul>
	 </div>)}
        </div>
	<div className="job">
	  <h3 className={isListHidden5 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden5(!isListHidden5)}>
          SW Asset Manager</h3>
	  {!isListHidden5 && (
          <div className="note-content">
          <p>
            Nokia · Jan 2010 - Jul 2011 · 1 yr 7 mos
          </p>
          <ul>
            <li>Part of the Product Platforms Product Management team, responsible for Configurations Content Management and Definition, Business Justifications, and Requirements Quality.</li>
            <li>Content definition of SW\HW configurations for S60 products. Configuration Requirement Management of HW drivers, Baseports, ENOSW, phone’s low-level SW.</li>
          </ul>
	</div>)}
        </div>

        <div className="job">
	  <h3 className={isListHidden6 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden6(!isListHidden6)}>
          S60 WLAN Core SW Project Manager</h3>
	  {!isListHidden6 && (
          <div className="note-content">
          <p>
            Nokia · 2004 - 2006 · 2 yrs
          </p>
          <ul>
            <li>Leading R&D team responsible for the development of WLAN solution for Symbian and ISA mobile phones.</li>
            <li>Project Planning and Scheduling, Requirement management, Error Management, Project Follow up, Solid line management responsibilities.</li>
            <li>WLAN, WiMax, CCX certification, EAP, etc.</li>
          </ul>
	 </div>)}
        </div>

        <div className="job">
	  <h3 className={isListHidden7 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden7(!isListHidden7)}>
          S90 Networking Project Manager</h3>
	  {!isListHidden7 && (
          <div className="note-content">
          <p>
            Nokia · 2003 - 2004 · 1 yr
          </p>
          <ul>
            <li>Managing SW Development team (SW developers, architect, and test engineers).</li>
            <li>Work Planning, Roadmapping, Requirements handling and analysis, Subcontracting responsibilities.</li>
            <li>Worked on Nokia 7100 family products, dealing with GPRS, EGPRS, CSD, HSCSD, WCDMA, WLAN technologies.</li>
          </ul>
	</div>)}
        </div>
  
	 <div className="job">
	  <h3 className={isListHidden8 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden8(!isListHidden8)}>
          SW Test Manager</h3>
	  {!isListHidden8 && (
          <div className="note-content">
          <p>
            2002 - 2003 · 1 yr
          </p>
          <ul>
            <li>Managing a team of test engineers in Multimedia Messaging project.</li>
            <li>Test Planning and Synchronization, Team leading, Operator negotiations.</li>
            <li>Error Management Responsibilities.</li>
            <li>Test and Error management related to MMS and IM (Instant Messaging) technologies.</li>
            <li>Filed Testing, Dealing with Operator Error Case.</li>
          </ul>
	  </div>)}
	  </div>
	  <div className="job">
	  <h3 className={isListHidden9 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden9(!isListHidden9)}>
	  Calypso Build and Integration Manager</h3>
	  {!isListHidden9 && (
          <div className="note-content">
          <p>
            2001 - 2002 · 1 yr
          </p>
          <ul>
            <li>Managing SW Build and Integration process, Coordinating deliveries from SW Developers and Subcontractors.</li>
            <li>Analyzing and correcting build problems.</li>
            <li>Planning SW release schedules. Creating and improving SW build tools.</li>
            <li>Taking care of SW Build and Integration process for Nokia 7650 Product Program.</li>
          </ul>
	  </div>)}
	  </div>
	  <div className="job">
	  <h3 className={isListHidden10 ? 'clickable-heading' : 'clickable-heading active'} onClick={() => setIsListHidden10(!isListHidden10)}>
          SW Test Engineer</h3>
	  {!isListHidden10 && (
          <div className="note-content">
          <p>
            2000 - 2001 · 1 yr
          </p>
          <ul>
            <li>Nokia 7650-phone project, Test planning and execution, Error handling with SW Development team.</li>
            <li>Lotus Error Database, PCP, EPOC emulator, Test Director, etc.</li>
          </ul>
	</div>)}
        </div>
      </section>

    </div>
  );
};

export default CV;

