// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css'; // Import the scoped CSS module for styling

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [isShaking, setShaking] = useState(false);
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setShaking(true);

    setTimeout(() => {
      setShaking(false);
    }, 300);

    setPassword(e.target.value);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const hardcodedPassword = 'fOoBaR2000';

    if (password === hardcodedPassword) {
      onLogin();
      navigate('/');
    } else {
      alert('Incorrect password. Please try again.');
      setPassword('');
    }
  };

  return (
    <div className={styles['login-body']}>
      <div className={`${styles['login-container']} ${isShaking ? styles.shake : ''}`}>
        <h2>Login</h2>
        <form onSubmit={handleLoginSubmit}>
          <div className={`input-container ${isShaking ? styles.shake : ''}`}>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
            />
          </div>
          <button
            type="submit"
            className={`${styles['login-button']} ${isShaking ? styles.shake : ''}`}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

